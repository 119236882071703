import React from 'react';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import Carousel from '../components/Carousel/Carousel';
import Presentation from '../components/Presentation/Presentation';
import Who from '../components/Who/Who';
import Mission from '../components/Mission/Mission';
import Values from '../components/Values/Values';

const Accueil = () => {
  return (
    <Layout>
      <Seo title="COS - Accueil" description="COS - Accueil" />
      <Carousel imagesDesktop={[]} imagesMobile={[]} />
      <Presentation />
      <Who />
      <Mission />
      <Values />
    </Layout>
  );
};

export default Accueil;
