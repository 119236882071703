import React from 'react';
import './Presentation.css';
import COS_PRENSENTATION_LOGO from '../../images/COS_PRESENTATION_LOGO.png';
import COS_PRENSENTATION_FRANCE from '../../images/COS_PRESENTATION_FRANCE.png';
import COS_PRENSENTATION_PIN from '../../images/COS_PRESENTATION_PIN.png';
import Text from '../Text/Text';

const Presentation = () => {
  const RAZZLE_PRESENTATION_PHARMACIES_COUNT =
    process.env.RAZZLE_PRESENTATION_PHARMACIES_COUNT;
  return (
    <div className="Presentation">
      <div className="Presentation__left">
        <img
          src={COS_PRENSENTATION_LOGO}
          className="Presentation__logo"
          alt=""
        />
      </div>
      <div className="Presentation__center"></div>
      <div className="Presentation__right">
        <div className="Presentation__top">
          <img
            src={COS_PRENSENTATION_FRANCE}
            className="Presentation__france"
            alt=""
          />
          <Text classname="Presentation__title" white={true}>
            + de {RAZZLE_PRESENTATION_PHARMACIES_COUNT} Pharmacies
          </Text>
        </div>
        <div className="Presentation__bottom">
          <img
            src={COS_PRENSENTATION_PIN}
            className="Presentation__pin"
            alt=""
          />
          <Text classname="Presentation__label" white={true}>
            Retrouvez les pharmacies COS partout en France
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
